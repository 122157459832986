export default {
  page: {
    title: {
      base: 'Портал за плащания Autopay',
      start: 'Избор на начин на плащане',
      visadata: 'Visa Mobile Payment',
      continue: 'Продължение - Избор на начин на плащане',
      wait: 'В очакване',
      confirmation: 'Потвърждение на плащането',
      thankYou: 'Благодарим Ви за плащането',
      error: 'Грешка',
      errorNotFound: 'Страницата не е намерена',
      secureBack: 'Зареждане на страницата',
      maintenance: 'Страницата е в процес на профилактика',
      regulationsApproval: 'Общи Условия и Политика за Поверителност',
      toTransferData: 'данни за банковия превод',
      sessionTimeout: 'Страница след приключването на сесията',
      paywayError: 'Каналът за плащане е недостъпен'
    }
  },
  header: {
    availabilityAdjust: {
      increaseFontSize: 'Увеличи размера на шрифта',
      decreaseFontSize: 'Намали размера на шрифта',
      changeContrast: 'Промени контрастта',
      changeLanguage: 'Смени езика на страницата',
      skipToContent: 'Преминете към съдържанието',
      skipToContentLabel: 'Преминете към основното съдържание',
      logoTitle: 'Лого на сайта'
    },
    sessionTimer: {
      timeOut: 'Сесията е изтекла',
      sessionEnd: 'Сесията ще изтече след:',
      label: 'Продължи времето на сесията',
      button: 'Продължи'
    }
  },
  footer: {
    text1: 'Имате допълнителни въпроси свързани с онлайн плащанията или други наши услуги? <a title="Отваря се в нов прозорец" href="https://pomoc.bluemedia.pl/?pk_campaign=paywall&pk_kwd=start&pk_medium=hyperlink" class="footer-text-link" target="_blank" rel="noreferrer">Отидете на помощната страница</a>',
    text2: {
      part1: 'Администратор на личните данни е Autopay S.A.',
      part2: 'ул. Повстанцув Варшави №6, пощ. код 81-718, Сопот. Предоставянето на лични данни е доброволно, но и същевременно необходимо за попълване на дадената заявка. Правното основание, целта, периода на обработване на личните данни и правата на Потребителя, както и друга важна информация относно правилата за обработка на лични данни, са подробно описани в <a href="{privacyPolicyUrl}" class="footer-text-link" target="_blank" title="Отидете на страницата с документа за Политика за Поверителност (128 KB, PDF)" rel="noreferrer">Политиката за Поверителност на Autopay S.A.</a>'
    },
    linkShowMoreTitle: 'Покажи допълнителната информация',
    linkShowMoreText: 'Прочети повече',
    linkShowLessTitle: 'Скрий допълнителната информация',
    linkShowLessText: 'Прочети по-малко'
  },
  paywall: {
    header: 'Избери начина на плащане',
    info: {
      paywayNotAvailable: 'Съжаляваме много, но изглежда, че избраният начин на плащане не е възможен в този момент на Вашето устройство.',
      applePayNotAvailable: 'Съжаляваме много, но изглежда, че в момента плащането с Apple Pay не е възможно на Вашето устройство.',
      googlePayNotAvailable: 'Съжаляваме много, но изглежда, че в момента плащането с Google Pay не е възможно на Вашето устройство.'
    },
    paywayGroup: {
      inactiveMessage: 'В момента плащането не е налично',
      notice: {
        novelty: 'НОВО',
        promotion: 'ПРОМОЦИЯ',
        recommended: 'ПРЕПОРЪЧВАМЕ',
        mastercard: 'БЪРЗО И БЕЗОПАСНО',
        mastercardC2P: 'БЪРЗО И БЕЗОПАСНО',
        blik: 'BLIKOMANIA – регистрирайте се и спечелете диаманти!',
        visaMobile: 'Плащайте гладко и безопасно'
      },
      bank: {
        many: 'банки',
        one: 'банка',
        two: 'банки'
      },
      wallet: {
        many: 'портфейли',
        one: 'портфейл',
        two: 'портфейли'
      },
      otp: {
        many: 'начини',
        one: 'начин',
        two: 'начини'
      },
      blikPayLater: {
        info: 'Научете повече'
      }
    },
    paywayList: {
      label: 'Група видове плащане',
      linkSelectOther: 'Избери друг'
    },
    paywaySingle: {
      accountAtAnotherBank: 'Имам сметка в друга банка'
    },
    paywaySwitch: {
      methodShortSingle: 'Еднократно',
      methodLongSingle: 'Еднократни плащания',
      methodShortAuto: 'Периодично',
      methodLongAuto: 'Периодични плащания'
    },
    form: {
      payway: {
        label: 'Избери плащане'
      },
      card: {
        label: 'Въведете данните картата',
        iframe: {
          title: 'Формуляр за попълване с данни за кредитна карта'
        }
      },
      visaMobile: {
        iframe: {
          title: 'Формуляр за попълване с телефонен номер'
        },
        status: {
          pending: {
            statusText: 'Чакане на потвърждение',
            header: 'Потвърди плащането в мобилното приложение',
            descriptionText1: 'Плащането ще потвърдиш в мобилното приложение Visa&nbsp;Mobile или приложението за мобилно банкиране на вашата банка с активирана услуга Visa&nbsp;Mobile',
            descriptionText2: 'След потвърждението на транзакцията в приложението, очаквайте резултата от плащането',
            descriptionText3: 'Тече анулиране на транзакцията',
            cancelButton: 'Анулиране на плащането'
          },
          success: {
            statusText: 'Благодарим ви за направената покупка!',
            descriptionText: 'Плащането завърши успешно'
          },
          error: {
            statusText: 'Уверете се, че имате достатъчно средства в картата, която използвате за плащания с Visa&nbsp;Mobile.',
            descriptionText: 'Плащането е отхвърлено'
          }
        },
        apps: 'Приложения, поддържащи плащания с Visa&nbsp;Mobile',
        help: 'Трябва Ви помощ?',
        info: 'Научете повече',
        secure: 'Сигурно плащане благодарение на криптирането SSL',
        modal2: {
          header: 'За да потвърдите плащането отворете приложението Visa&nbsp;Mobile или приложението за мобилно банкиране на вашата банка, в която имате активирана услуга Visa&nbsp;Mobile',
          listText1: 'имате инсталирано приложение Visa&nbsp;Mobile или активирана услуга Visa&nbsp;Mobile в мобилното приложение на твоята банка',
          listText2: 'имате включени нотификации от приложението Visa&nbsp;Mobile или приложението за мобилно банкиране на вашата банка с активирана услуга Visa&nbsp;Mobile',
          listText3: 'въведения от Вас номер е правилен: <strong class="no-wrap">+{prefix} {phone}</strong>',
          listText4: 'Уверете се също така, че....'
        }
      },
      click2pay: {
        loaderText: 'Търсим вашия имейл адрес<br>в <strong>Щракнете за плащане</strong>',
        saveCard: {
          text1: 'Запазете вашата карта',
          text2mc: 'Вашите данни ще бъдат запазени безопасно в <strong>Mastercard Click to Pay</strong>',
          text2visa: 'Вашите данни ще бъдат запазени сигурно във <strong>Visa Click to Pay</strong>',
          link: 'Научете повече'
        },
        email: {
          labelForClick2Pay: 'Имейл адрес за известия за плащане'
        },
        cardSelect: {
          selected: 'Избрано',
          select: 'Изберете',
          payOther: 'Въведете данни за картата',
          back: 'Назад'
        },
        cardSelected: {
          header: 'Използване на карта, запазена в Click to Pay',
          date: 'валиден до',
          buttonChange: 'Промяна'
        },
        codeForm: {
          description: 'Намерихме вашия имейл адрес в Click to Pay.',
          codeSentEmail: 'Въведете еднократния код, който изпратихме на {email}, за да влезете в акаунта си.',
          codeSentPhone: 'Въведете еднократния SMS код, който изпратихме на {phone}, за да влезете в акаунта си.',
          codeSent: 'Въведете еднократния код, който изпратихме на {validationChannel}, за да влезете в акаунта си.',
          codeLabel: 'Въведете код',
          codePlaceholder: 'въведете код',
          codeLabelFlow: 'Код',
          codeSendLabel: 'Изпращане на нов код',
          codeSendAriaLabel: 'чрез',
          codeSendAriaLabel2: 'или',
          buttonSendNewSms: 'SMS',
          buttonSendNewEmail: 'имейл',
          trustedLabel: 'Добавете това устройство към вашия списък с надеждни<br><span>Следващия път ще разпознаем това устройство и ще платите без да влизате</span>',
          buttonSubmit: 'Напред',
          buttonChangeCard: 'Въведете данни за картата'
        },
        info: {
          description: 'Плащането с карта е криптирано и защитено. Транзакцията ще бъде разрешена с помощта на 3DSecure на уебсайта на банката.'
        },
        loginForm: {
          emailLabel: 'Въведете вашия имейл адрес, за да влезете в Click to Pay',
          emailPlaceholder: 'имейл адрес',
          emailLabelFlow: 'Имейл',
          buttonSubmit: 'Вход',
          buttonBack: 'Назад'
        },
        newUserForm: {
          header: 'Запазване на карта в Click to Pay',
          description: 'Нуждаем се от още няколко подробности, за да запазим вашата карта.',
          emailLabel: 'Въведете имейл адрес',
          emailPlaceholder: 'имейл адрес',
          emailLabelFlow: 'Имейл',
          countryLabel: 'Държава',
          firstnameLabel: 'Име',
          firstnamePlaceholder: 'име',
          firstnameLabelFlow: 'Име',
          lastnameLabel: 'Фамилия',
          lastnamePlaceholder: 'lastname',
          lastnameLabelFlow: 'Фамилно име',
          phoneLabel: 'Телефонен номер',
          phonePlaceholder: 'телефонен номер',
          phoneLabelFlow: 'Телефон',
          countryCodePlaceholder: 'код',
          countryCodeLabelFlow: 'Код',
          trustedLabel: 'Добавете това устройство към вашия списък с надеждни<br><span>Следващия път ще разпознаем това устройство и ще платите без да влизате</span>',
          termsMastercardLabel: 'Продължавайки, вие приемате <a href="https://www.mastercard.com/global/click-to-pay/country-listing/terms.html" target="_blank">Условия за ползване</a> и разбирате, че вашите данни ще бъдат обработени в съответствие с <a href="https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html" target="_blank">Политика за поверителност</a>Mastercard.',
          termsVisaLabel: 'Продължавайки, вие приемате <a href="{c2pVisaTermsUrl}" target="_blank">Общите условия на Visa Solution</a> и разбирате, че вашите данни ще бъдат обработени в съответствие с <a href=" {c2pVisaPrivacyUrl}" target="_blank">Правила за поверителност на Visa</a>.',
          cookieVisaLabel: 'Продължавайки, вие приемате <a href="{c2pVisaCookieUrl}" target="_blank">Правилата за бисквитки на Visa</a>.',
          c2pInfo: 'Click to Pay ще използва тази информация, за да ви потвърди и да изпрати кодове за потвърждение на този номер. Може да се наложат такси за съобщения/данни.',
          buttonSubmit: 'Запази и плати',
          buttonBack: 'Плащане без запазване на вашата карта'
        },
        profile: {
          header: 'Влезте, за да кликнете за плащане',
          buttonChangeEmail: 'Промяна на имейл адрес'
        },
        modal: {
          title: 'Click to Pay - информация',
          header: 'Плащайте бързо и безопасно, без да пренаписвате данните на картата си в новия стандарт Click to Pay',
          text1: 'Когато плащате с карта, потърсете логото <img src="/web/img/click2pay/Click_to_Pay.svg" style="height: 14px" alt="Click to Pay Logo"> и платете с платежната си карта бързо и удобно.',
          text2: 'Click To Pay е нов стандарт за плащане с карта, поддържан от Visa и Mastercard.',
          text3: 'Можете да плащате с картата на вашия акаунт Click to Pay в магазини в Полша и в чужбина, без да копирате данните на картата.',
          text4: 'Данните се съхраняват сигурно във Visa или Mastercard. Цялата информация е напълно криптирана.',
          text5: 'По време на следващи плащания Click to Pay ще разпознае вашия имейл адрес, така че можете да платите незабавно със запазените си карти.',
          text6: 'Допълнително потвърждавате плащанията във вашата банка, което гарантира пълна сигурност.',
          processError: 'Възникна грешка при извършване на плащането Click to Pay. Направете стандартно плащане с карта.'
        }
      },
      email: {
        label: 'Въведи e-mail адрес',
        flowLabel: 'E-mail',
        tip: 'Трябва ни Вашият e-mail адрес, за да можем да Ви изпращаме съобщения относно статуса на вашето плащане.'
      },
      terms: {
        header: 'Споразумения и декларации',
        selectAll: 'Маркирам всички',
        fieldNotRequired: 'Полето не е задължително',
        partnerMarketingTextLess: 'Искате ли да получавате известия по имейл, SMS или телефон за най-добрите оферти, промоции, конкурси или други интересни събития, организирани от нас или от нашите Партньори?',
        partnerMarketingTextMore: 'Обещаваме да не спамим! Ако нашите съобщения Ви отегчават, можете да оттеглите съгласието си по всяко време.',
        tradeMarketingTextLess: 'Искате ли да можем да предоставим на нашите доверени партньори вашия имейл или телефонен номер, за да могат да ви изпращат интересна информация и оферти?',
        tradeMarketingTextMore: 'Уверяваме ви, че наши партньори са авторитетни фирми, които като нас не обичат да спамят, а съгласието можете по всяко време да анулирате.'
      },
      termsLabelDefault: 'Запознах се и приемам <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Свали файла с общите условия на услугата" rel="noreferrer">Общите условия</a> за предоставяне на платежни услуги и <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Свали файла с Политиката за Поверителност на Autopay S.A.">Политиката за Поверителност</a>. Бих искал услугата да бъде предоставена незабавно, а в случай на отказ от договора, знам, че няма да получа възстановяване на разходите за услугите, извършени по мое искане, до момента на отказ на договора.',
      termsReccuring: 'Ако искате да направите плащането, трябва да приемете <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Свали файла с общите условия на услугата" rel="noreferrer">Общите Условия</a> и <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Свали файла с Политиката за Поверителност на Autopay S.A.">Политиката за Поверителност</a> на Autopay S.A., даване на съгласие за незабавно изпълнение на платежната услуга и съгласие за таксуване на платежната ми карта',
      termsReccuringMore1: 'Съгласен съм услугата да бъде изпълнена незабавно и в случай на отказ от договора по мое желание, знам, че няма да получа възстановяване на разходите по предоставените услуги до момента на отказ от договора.',
      termsReccuringMore2: 'Съгласен съм Autopay S.A. да таксува платежната ми карта периодично, за да изпълнява транзакции от името на {receiverName}. Правилата за периодично таксуване на картата се определят в<a href="{regulationUrl}" class="form-text-link" target="_blank" title="Свалете документа с Общите Условия на услугата" rel="noreferrer">Общите Условия</a> за предоставяне на платежни услуги. Декларирам, че съм запознат със сроковете, периодичността и правилата за определяне на таксите по моята платежна карта, както и с начините за оттегляне на настоящото съгласие, които са определени от мен с {receiverName}. Ще се свържа с {receiverName}, ако имам въпроси относно повтарящи се таксувания на платежната карта.',
      mwfClauseLess: 'Ако искате да направите плащане, приемете <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Изтеглете документа с Общите Условия на услугата" rel="noreferrer">Общите Условия</a> и <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Свалете файла Политиката за Поверителност на Autopay S.A.">Политиката на Поверителност на Autopay S.A.</a>, даване съгласие за започване на транзакцията във Вашата банка с помощта на Autopay S.A. и предоставяне на данните за тази транзакция на получателя както и искане за незабавно изпълнение на услугата за плащане.',
      mwfClauseMore: 'Искам, услугата да бъде предоставена незабавно и знам, че няма да ми се възстановяват разходите, в случай на отказ от договора. ',
      mwfClauseARIA: 'Ако искате да направите плащане, приемете Общите Условия и Политиката за Поверителност на Autopay S.A., съгласие за започване на транзакция във Вашата банка с помощта на Autopay S.A. и предоставяне на подробности за транзакция към получателя както и искане за незабавно изпълнение на платежната услуга. Искам услугата да бъде предоставена незабавно и знам, че няма да получа възстановяване на разходите, ако се откажа от договора. <a href="{regulationUrl}" class="form-text-link" target="_blank" title="" rel="noreferrer" tabindex="-1">Свалете документа с Общите Условия на услугата</a> <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="" tabindex="-1">Изтеглете файла с политиката за поверителност на Autopay S.A.</a>',
      paymentInfo: 'Платежното нареждане се подава чрез Autopay S.A. със седалище в Сопот и ще бъде изпълнено в съответствие с условията, определени от вашата банка. След&nbsp; като изберете банка, ще авторизирате плащането.',
      changePaywayLink1: 'Все още можеш',
      changePaywayLink2: 'да промениш формата на плащане',
      linkMore: 'ПОВЕЧЕ',
      linkShowMoreTitle: 'Покажи допълнителната информация',
      linkLess: 'ПО-МАЛКО',
      linkShowLessTitle: 'Скрий допълнителната информация',
      submitButton: {
        visaMobile: 'Плати с',
        fastTransfer: 'Генерирай детайлите на превода',
        mwf: 'Приемам и плащам',
        pis: 'Приемам и започвам плащането',
        auto: 'Направете периодично плащане',
        autoPayment: 'Направете плащане',
        other: 'Плащам',
        orange: 'Потвърди',
        c2pNext: 'Следващ',
        c2pAccept: 'Приемам',
        c2pSaveAndPay: 'Спести и плати'
      }
    },
    frData: {
      pageTitle: 'Подробни данни за превода',
      header: 'Направи преводи, използвайки данните',
      nrbLabel: 'Сметка на получателя',
      nameLabel: 'Име на получателя',
      addressLabel: 'Адрес на получателя',
      titleLabel: 'Основание за плащане на превода',
      amountLabel: 'Сума на превода',
      timeLabel: 'Транзакцията изпратете към',
      linkCopy: 'Копирай',
      linkGetPdf: 'Свали PDF с данните',
      linkGetPdfTitle: 'Отваря се в нов прозорец',
      linkGoToBank: 'Логнете се в банката',
      info: 'Влезте в банковата си сметка. Копирайте данните в превода. Важно е, данните да са същите, не променяйте сумата, валутата, основанието за плащане, и т.н.'
    },
    processing: {
      pageTitle: 'Страница за изчакване за обработка на транзакцията',
      loaderAnimation: 'Докато чакате се показва анимация',
      header: 'Приготвяме вашата страница за плащане.',
      description: 'Почакай още малко...',
      info: {
        header: 'Запомни!',
        description: 'След завършването на транзакцията не затваряй страницата - ако направиш това, парите ти няма да се изпратят на получателя.'
      },
      loaderText: 'Извършва се изпращане на данните.<br>Това ще ни отнеме не повече от 30 секунди.'
    }
  },
  thankYou: {
    pageTitle: 'Информация за резултата от транзакцията',
    form: {
      emailLabel: 'E-mail',
      buttonSubmit: 'Върнете се на сайта на партньора'
    },
    negative: {
      header: 'Плащането не е извършено поради липса на авторизация на транзакцията.',
      description: 'Ако все пак искате да използвате офертата на даден продавач или доставчик на услуги - върнете се на неговия уебсайт, изберете продукта или услугата и направете плащането по избрания от вас начин.'
    },
    pending: {
      header: 'Благодарим ви, че направихте плащане чрез портала за плащания.',
      description: 'Статуса на транзакцията се проверява. Когато процесът приключи, ще получите информация за статуса на плащането на вашия имейл.'
    },
    positive: {
      header: 'Благодарим ви, плащането ви е осъществено.',
      description: 'Ще изпратим потвърждение на имейл адреса, който сте посочили.'
    }
  },
  regulationsApproval: {
    header: 'Потвърдете транзакцията'
  },
  wait: {
    pageTitle: 'Страница за изчакване'
  },
  sessionTimeout: {
    pageTitle: 'Страница след края на сесията',
    header: 'Времето за извършване на плащането е изтекло.',
    description: 'Можете да се върнете на сайта на партньора по всяко време, да направите поръчка отново или да използвате услугата и да платите посредством избрания от вас начин.'
  },
  paywayError: {
    pageTitle: 'Избраният канал за плащане не е наличен',
    header: 'За съжаление, избраният начин на плащане не е достъпен в момента.',
    description: 'Моля, върнете се на сайта на партньора и опитайте отново по-късно.'
  },
  loader: {
    defaultAnimation: 'Анимация, показвана при зареждане на страницата ',
    slider: 'Слайдър със сменящи се текстове',
    text1: 'В Autopay се стремим към въглеродна неутралност. Вие&nbsp;също&nbsp;го правите, като използвате нашите услуги.',
    text2: 'Ние в Autopay пътуваме с велосипед до работа, намалявайки емисиите на парникови газове в атмосферата. Вие също допринасяте за това, ползвайки нашите услуги.',
    text3: 'Ние в Autopay се захранваме от зелена енергия.<br>Вие също я ползвате, използвайки нашите услуги.',
    text4: 'В Autopay ограничаваме употребата на хартия. Вие&nbsp;също&nbsp;допринасяте за това, като ползвате нашите услуги.',
    rateLimitExceeded: 'There is increased transaction traffic, so processing your payment may take longer than usual. Please wait and do not refresh the payment page.'
  },
  orderDetails: {
    header: 'Детайли',
    headerAriaLabel: 'Подробности за транзакцията',
    amountLabel: 'Дължима сума',
    receiverLabel: 'Получател',
    orderLabel: 'Номер на поръчката',
    amountOryginalCurrencyLabel: 'Сума в {currency}',
    currencyRateLabel: 'Обменен курс',
    feeLabel: 'Такса за извършване на транзакцията',
    customerNumberLabel: 'Номер на клиента',
    invoiceNumberLabel: 'Номер на фактурата',
    accountNumberLabel: 'Номер на сметката',
    paywayLabel: 'Начин на плащане',
    paywayName: 'Онлайн банков превод',
    linkBack: 'Връщане в магазина',
    linkBackTitle: 'Напуснете транзакцията и се върнете в магазина ',
    linkMore: 'повече',
    linkLess: 'по-малко',
    toggleBtnUnfoldAriaLabel: 'Покажи подробностите за поръчката',
    toggleBtnFoldAriaLabel: 'Скрий подробностите за поръчката'
  },
  error: {
    pageTitle: 'Страница с информация за грешката',
    linkBackUrlSet: 'Върнете се на сайта на партньора',
    linkBackUrlNotSet: 'Отидете на помощната страница',
    sessionTimeout: {
      title: 'Времето за извършване на плащането е изтекло',
      message: 'Можете да се върнете на уебсайта на партньора по всяко време, да направите поръчка отново или да използвате услугата и да платите по избрания от вас начин.'
    },
    notFoundError: {
      pageTitle: 'Страница с информация за грешка 404 - страницата не е намерена',
      title: 'Изглежда сте отишли твърде далеч и страницата, която искате да посетите, не може да бъде намерена.',
      message: 'Възможни причини: страницата не съществува или е била изтрита, в процес на профилактика, адреса на страницата е грешен, възникнала е техническа грешка.'
    },
    generalError: {
      title: 'Извиняваме се',
      message: 'В момента не можем да извършиме Вашия превод.'
    },
    errFieldNotFound: {
      title: 'Извиняваме се',
      message: ''
    },
    errBadClientSource: {
      title: 'Извиняваме се',
      message: ''
    },
    nrParametersError: {
      title: 'Извиняваме се',
      message: 'В момента не можем да извършиме Вашия превод.'
    },
    transactionOutdated: {
      title: 'Времето за завършване на плащането е изтекло.',
      message: 'Направете го отново.'
    },
    linkValidityTimeOutdated: {
      title: 'Времето за завършване на плащането е изтекло.',
      message: 'Направете го отново.'
    },
    transactionValidityTimeOutdated: {
      title: 'Времето за завършване на плащането е изтекло.',
      message: 'Направете го отново.'
    },
    multiplyTransaction: {
      title: 'Извиняваме се',
      message: 'Транзакцията вече съществува и очаква плащане.'
    },
    transactionCanceled: {
      title: 'Извиняваме се',
      message1: 'Транзакцията е била анулирана.',
      message2: 'Направете я отново.'
    },
    multiplyPaidTransaction: {
      title: 'Извиняваме се',
      message: 'Транзакцията вече е платена.'
    },
    transactionReject: {
      title: 'Извиняваме се',
      message1: 'Ами сега, нещо се обърка.',
      message2: 'Поправяме портала... '
    },
    bankDisabled: {
      title: 'Извиняваме се',
      message: 'Избраната от Вас банка в момента не е достъпна.'
    },
    bankTemporaryMaintenance: {
      title: 'Извиняваме се',
      message: 'В момента не можем да обработим вашия превод.'
    },
    insufficientStartAmount: {
      title: 'Извиняваме се',
      message: 'Лимитът за сумата на превода е надхвърлен.'
    },
    startAmountOutOfRange: {
      title: 'Извиняваме се',
      message: 'Лимитът за сумата на превода е надхвърлен.'
    },
    nonAccountedLimitExceeded: {
      title: 'Извиняваме се',
      message: 'Плащането не може да бъде извършено, тъй като продавачът е достигнал месечния лимит на продажби.'
    }
  },
  confirmation: {
    pageTitle: 'Информация за хода на плащането',
    linkBack: 'Връщам се на сайта',
    linkBackTimeout: 'Върнете се на уебсайта на партньора след ',
    incorrectAmount: {
      header: 'Грешна сума',
      description: 'Не прехвърлихме вашето плащане към получателя, защото сумата беше неправилна.',
      description2: 'Щракнете върху бутона, за да се върнете на страницата и да я повторите. Не забравяйте да не променяте никакви данни.'
    },
    multiplePaid: {
      header: 'Двоен депозит',
      description: 'Получателят получи вашето плащане два пъти. Не се притеснявайте – ще получите сумата си възстановена.',
      description2: 'Свържете се с получателя и му предоставете потвърждение за плащане.'
    },
    negative: {
      header: 'Без плащане',
      description: 'Възникна грешка при обработката на вашето плащане. Но нищо не е загубено.',
      description2: 'Щракнете върху бутона, за да се върнете на страницата и да я повторите.'
    },
    notFound: {
      header: 'Без плащане',
      description: 'Възникна грешка при обработката на вашето плащане. Но нищо не е загубено.',
      description2: 'Щракнете върху бутона, за да се върнете на страницата и да я повторите.'
    },
    outdated: {
      header: 'Край на времето',
      description: 'Не прехвърлихме вашето плащане на получателя.',
      description2: 'Щракнете върху бутона, за да се върнете на страницата и да я повторите.'
    },
    outdatedPaidNotRefunded: {
      header: 'Край на времето',
      description: 'Не сме прехвърлили вашето плащане към получателя. Ако парите са изтеглени от сметката ви, не се притеснявайте, ще получите възстановяването си до няколко дни.',
      description2: 'Щракнете върху бутона, за да се върнете на страницата и да я повторите.'
    },
    outdatedPaidRefunded: {
      header: 'Край на времето',
      description: 'Не прехвърлихме вашето плащане на получателя. Ако парите са били изтеглени от сметката ви, не се притеснявайте, вече ги възстановихме.',
      description2: 'Щракнете върху бутона, за да се върнете на страницата и да я повторите.'
    },
    pending: {
      header: 'Благодарим ви, че направихте плащане посредством портала за плащания.',
      description: 'Проверяваме статуса на транзакцията. Когато процесът приключи, ще получите информация за статуса на плащането на Вашия имейл.'
    },
    positive: {
      header: 'Успяхме! Благодарим ви, че направихте плащането чрез портала за плащания.',
      visaVideo: 'Рекламен клип, пускан след успешна транзакция',
      description: 'Средствата ще бъдат преведени на посочения получател и ще получите потвърждение на транзакцията на вашия имейл.'
    }
  },
  messages: {
    accessForbidden: 'Липса на достъп',
    internalServerError: 'Възникна неочаквана грешка. Опитайте отново по-късно.',
    validators: {
      required: 'Полето е задължително',
      pattern: 'Моля, посочете валидна стойност',
      emailRequired: 'Въведете валиден e-mail адрес',
      emailNotValid: 'Въведете валиден e-mail адрес',
      emailNotRecognized: 'Предоставеният имейл адрес не е регистриран',
      emailIdentityLookup: 'Неуспешна проверка на имейл адреса',
      emailLocked: 'Предоставеният имейл адрес е временно блокиран в системата на Mastercard',
      paywayRequired: 'За да платите изберете банка',
      creditCardRequired: 'Попълнете всичките полета',
      phoneRequired: 'Въведете валиден телефонен номер',
      regulationRequired: 'Приемете Общите Условия',
      requiredConsent: 'Необходимо е съгласие',
      codeNotValid: 'Предоставеният код не е валиден.',
      codeAccountLocked: 'Сметката е временно блокирана.',
      unknownError: 'Възникна неочаквана грешка'
    },
    validatorsAria: {
      required: 'Внимание. Полето на формуляра не е попълнено. Полето е задължително.',
      pattern: 'Внимание. Полето съдържа невалидна стойност.',
      emailRequired: 'Внимание. Полето за имейл не е попълнено. Въведете валиден имейл адрес.',
      emailNotValid: 'Внимание. Във вашият имейл адрес има грешка. Въведете валиден имейл адрес.',
      emailNotRecognized: 'Внимание. Предоставеният имейл адрес не е регистриран.',
      emailIdentityLookup: 'Внимание. Предоставеният имейл адрес не можа да бъде потвърден',
      emailLocked: 'Внимание. Предоставеният имейл адрес е временно блокиран в системата на Mastercard.',
      paywayRequired: 'Внимание. Не е избран начина на плащане. Изберете начина на плащане.',
      creditCardRequired: 'Внимание. Попълнете всички полета с данните на кредитната карта.',
      phoneRequired: 'Внимание. Полето за телефонен номер съдържа грешка. Въведете валиден телефонен номер.',
      codeNotValid: 'Внимание. Предоставеният код не е валиден.',
      codeAccountLocked: 'Внимание. Сметката е временно блокирана.',
      unknownError: 'внимание. Възникна неочаквана грешка'
    },
    success: {
      copy: 'Копирано!',
      codeSent: 'Кодът е изпратен!'
    },
    errors: {
      getPdf: 'Неуспешно изтегляне на PDF файла!',
      copy: 'Неуспешно копиране!',
      thankYouUpdate: 'Не успяхме да запишем имейл адреса!',
      groupInactive: 'Понастоящем е невъзможно да се направи трансфер с помощта на групата {groupName}. Моля, изберете друг метод на плащане.',
      paywayInactive: 'В момента не е възможно да се направи превод посредством избраната банка. Моля, изберете друга банка или начин на плащане.',
      googlePayInactive: 'За съжаление Google Pay не е наличен на Вашето устройство.',
      applePayInactive: 'Apple Pay е достъпен за хора, използващи браузъра Safari и устройства с операционна система iOS.',
      codeNotSent: 'Неуспешно повторно изпращане на код!',
      codeCounterExceeded: 'Броят разрешени опити е надвишен. Въведете данните за вашата карта ръчно или използвайте друг метод на плащане.'
    }
  },
  modal: {
    closeButton: 'Затвори',
    confirmButton: 'Разбрах'
  },
  configuration: {
    paywayGroups: {
      auto: {
        label: 'Периодично плащане',
        description: 'Въведете данните на картата, която ще използвате за плащане. Автоматични плащания ще бъдат правени от вашата карта по-нататък.',
        labelInitWithRefund: 'Верификационно плащане',
        labelInitWithRefundOrange: 'Добавяне на платежна карта',
        labelInitWithPayment: 'Плащане с карта',
        descriptionInitWithRefund: 'Въведете данните на картата, която искате да потвърдите за бъдещи плащания. На&nbsp;следващата стъпка ще изтеглиме една злота от дадената карта, за да провериме дали данните са верни. След като направиме проверката ще възстановим сумата.',
        descriptionInitWithPayment: 'Въведете данни на картата, с която ще извършите транзакцията и в същото време я&nbsp;верифицирайте&nbsp;за бъдещи плащания.'
      },
      blik: {
        label: 'BLIK',
        description: 'Платете веднага, използвайки код BLIK, без да се логвате или регистрирате'
      },
      card: {
        label: 'Плащане с карта',
        description: 'Платете с вашата карта'
      },
      pbl: {
        label: 'Онлайн превод',
        description: 'Изберете банката, от която искате да направите плащането',
        descriptionVerify: 'Онлайн проверка на самоличността',
        descriptionServiceVerify: 'Изберете банката, благодарение на която ще верифицирате онлайн услугата'
      },
      visaMobile: {
        label: 'Visa Mobile',
        description: 'Плащане с приложението Visa Mobile'
      },
      wallet: {
        label: 'Google&nbsp;Pay | Apple&nbsp;Pay',
        description: 'Плащайте без да е необходимо да се логнете в системата за онлайн банкиране'
      },
      installments: {
        label: 'Плати на вноски',
        description: 'Купете сега и платете на вноски'
      },
      alior: {
        descriptionInfo1: 'Плащайте покупките си чрез удобни вноски',
        descriptionInfo2: '(проверете подробностите)',
        aliorModal: {
          text1: 'В зависимост от настройките на уебсайта, от който правите покупката, можете да закупите продукти на изплащане в следните варианти:',
          text2: '<ul><li>10 вноски 0%</li><li>20 вноски 0%</li><li>от 3 до 48 вноски с месечна цена от 1% (според дадения представителен пример за дадена транзакция)</li></ul>'
        }
      },
      otp: {
        label: 'Плати по-късно',
        description: 'Купете сега, платете по-късно',
        descriptionInfo1Mwf: 'Платете по-късно сметката - до 45 дни наведнъж или на няколко равни вноски',
        descriptionInfo1Ecommerce: 'Платете по-късно - до 45 дни наведнъж или на няколко равни вноски',
        descriptionInfo2Mwf: 'Информация за кредитния посредник и разходите',
        descriptionInfo2Ecommerce: 'Информация за разходите',
        labelPayka: 'Payka',
        descriptionPayka: ' <a href="" id="paykaShowInModal"></a>',
        paykaModalMwf: {
          header1: 'Информация за кредитния посредник',
          text1: 'Autopay S.A. със седалище в Сопот действа като кредитен посредник на Paytree S.A. със седалище в Гданск (Кредитора), упълномощен да представя кредитната оферта на клиентите и да пренасочва към уебсайта на Заемодателя, включително и искането за кредит. Подробности за офертата ще намерите на: <a href="https://payka.pl/rachunki-domowe" target="_blank">Битови сметки- Payka</a>.',
          header2: 'Данни за разходите',
          text2: 'Платете по-късно - до 45 дни наведнъж или на няколко равни вноски. Данни за разходите за представителен пример: Погасяване в рамките на 45 дни ГПР 0%; Разсрочено погасяване ГПР 86,83%. Подробности за офертата на: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        paykaModalEcommerce: {
          header2: 'Данни за разходите',
          text2: 'Платете по-късно - до 45 дни наведнъж или на няколко равни вноски. Данни за разходите за представителен пример: Погасяване в рамките на 45 дни ГПР 0%; Разсрочено погасяване ГПР 86,83%. Подробности за офертата на: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        }
      },
      blikPayLater: {
        label: 'BLIK Платете по-късно',
        description: 'Услугата е достъпна за клиенти на Банка Милениум и ВелоБанк',
        modal: {
          header1: 'Купете сега, платете&nbsp;в&nbsp;рамките&nbsp;на&nbsp;30&nbsp;дни',
          header2: 'Активация',
          text2: 'Избирате опцията BLIK Плати по-късно, въвеждате BLIK кода, а ние задаваме лимитa за вашето пазаруване.<br>Максимума е 4000 PLN',
          header3: 'Пазаруване',
          text3: 'Купувате продукти, за които не плащате в момента на покупката. Имате време да проверите дали всичко ви подхожда.',
          header4: 'След всяко пазаруване',
          text4: 'Плащате в рамките на 30 дни без допълнителни такси. Можеш също така да върнеш стоките или да разделиш плащането на 3 части.',
          header5: 'Предлага се услуга'
        }
      },
      companyPayments: {
        label: 'Фирмени плащания',
        description: 'Удължете срока на плащане за фирмени покупки с 30 дни'
      },
      transfer: {
        label: 'Данни за превода',
        description: 'Направи превода, ползвайки дадените данни'
      },
      other: {
        label: 'Други',
        description: ''
      }
    },
    mobileEvents: {
      statusDescription: {
        bankDisabled: 'Извиняваме се. В момента не можем да обработим вашия превод. Избраната банка в момента не е достъпна.',
        blockMultipleTransactions: 'Извиняваме се. Транзакцията вече съществува и очаква плащане.',
        blockPaidTransactions: 'Извиняваме се. Транзакцията е вече платена. Не можете да извършите плащане отново.',
        canceledTransactions: 'Транзакцията, която се опитвате да платите, е анулирана и вече не е достъпна. Моля, започнете нова транзакция.',
        declined: 'Отказана авторизация.',
        generalError: 'Възникна временен проблем с връзката. В момента не можем да обработим вашия превод. Заповядайте по-късно.',
        insufficientStartAmount: 'Ограничението за сумата на превода е надвишено.',
        internalServerError: 'Упс...., не се получи. Работим върху проблема. Моля, опитайте отново по-късно.',
        nonAccountedLimitExceeded: 'Плащането не може да бъде извършено, тъй като продавачът е достигнал месечния лимит за продажби.',
        outdatedError: 'Времето за завършване на плащането е изтекло.',
        paid: 'Транзакцията е завършена успешно.',
        paywayLoaded: 'Страницата за експресен превод е заредена',
        paywaylistLoaded: 'Страницата за избор на начин на плащане се зареди'
      }
    }
  },
  maintenance: {
    text1: 'В платежния портал на Autopay тече новогодишна профилактика, поради което в момента не е възможно да се правят плащания. Извиняваме се за причиненото неудобството.',
    text2: 'Порталът ще заработи отново на 16 януари в 10:00 часа.',
    text3: 'Благодарим Ви за разбирането.'
  },
  requiredParams: {
    accountHolderName: {
      label: 'Дай ми името и фамилията си',
      flowLabel: 'име и фамилия',
      placeholder: 'Дай ми името и фамилията си'
    },
    nip: {
      label: 'Въведете своя данъчен идентификационен номер',
      flowLabel: 'NIP',
      placeholder: 'Въведете своя данъчен идентификационен номер'
    },
    customerEmail: {
      label: 'Въведете своя имейл адрес',
      flowLabel: 'Имейл',
      placeholder: 'Въведете своя имейл адрес'
    },
    iban: {
      label: 'Попълнете своя IBAN',
      flowLabel: 'IBAN',
      placeholder: 'Попълнете своя IBAN'
    }
  }
}
